define('@fortawesome/pro-regular-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faTrashCan = {
    prefix: 'far',
    iconName: 'trash-can',
    icon: [448, 512, [61460, "trash-alt"], "f2ed", "M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"]
  };
  var faTrashAlt = faTrashCan;
  var faUsers = {
    prefix: 'far',
    iconName: 'users',
    icon: [640, 512, [], "f0c0", "M144 160A80 80 0 1 0 144 0a80 80 0 1 0 0 160zm368 0A80 80 0 1 0 512 0a80 80 0 1 0 0 160zM0 298.7C0 310.4 9.6 320 21.3 320H234.7c.2 0 .4 0 .7 0c-26.6-23.5-43.3-57.8-43.3-96c0-7.6 .7-15 1.9-22.3c-13.6-6.3-28.7-9.7-44.6-9.7H106.7C47.8 192 0 239.8 0 298.7zM405.3 320H618.7c11.8 0 21.3-9.6 21.3-21.3C640 239.8 592.2 192 533.3 192H490.7c-15.9 0-31 3.5-44.6 9.7c1.3 7.2 1.9 14.7 1.9 22.3c0 38.2-16.8 72.5-43.3 96c.2 0 .4 0 .7 0zM320 176a48 48 0 1 1 0 96 48 48 0 1 1 0-96zm0 144a96 96 0 1 0 0-192 96 96 0 1 0 0 192zm-58.7 80H378.7c39.8 0 73.2 27.2 82.6 64H178.7c9.5-36.8 42.9-64 82.6-64zm0-48C187.7 352 128 411.7 128 485.3c0 14.7 11.9 26.7 26.7 26.7H485.3c14.7 0 26.7-11.9 26.7-26.7C512 411.7 452.3 352 378.7 352H261.3z"]
  };
  var faBarcodeRead = {
    prefix: 'far',
    iconName: 'barcode-read',
    icon: [576, 512, [], "f464", "M56 48c-4.4 0-8 3.6-8 8v80c0 13.3-10.7 24-24 24s-24-10.7-24-24V56C0 25.1 25.1 0 56 0h80c13.3 0 24 10.7 24 24s-10.7 24-24 24H56zm64 80c13.3 0 24 10.7 24 24V360c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zm56 16c0-8.8 7.2-16 16-16s16 7.2 16 16V368c0 8.8-7.2 16-16 16s-16-7.2-16-16V144zm272 0c0-8.8 7.2-16 16-16s16 7.2 16 16V368c0 8.8-7.2 16-16 16s-16-7.2-16-16V144zm-208 8c0-13.3 10.7-24 24-24s24 10.7 24 24V360c0 13.3-10.7 24-24 24s-24-10.7-24-24V152zm152-24c13.3 0 24 10.7 24 24V360c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM528 56c0-4.4-3.6-8-8-8H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h80c30.9 0 56 25.1 56 56v80c0 13.3-10.7 24-24 24s-24-10.7-24-24V56zM56 464h80c13.3 0 24 10.7 24 24s-10.7 24-24 24H56c-30.9 0-56-25.1-56-56V376c0-13.3 10.7-24 24-24s24 10.7 24 24v80c0 4.4 3.6 8 8 8zm472-8V376c0-13.3 10.7-24 24-24s24 10.7 24 24v80c0 30.9-25.1 56-56 56H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h80c4.4 0 8-3.6 8-8z"]
  };
  var faUser = {
    prefix: 'far',
    iconName: 'user',
    icon: [448, 512, [128100, 62144], "f007", "M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"]
  };
  var faStar = {
    prefix: 'far',
    iconName: 'star',
    icon: [576, 512, [11088, 61446], "f005", "M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"]
  };
  var faCirclePlay = {
    prefix: 'far',
    iconName: 'circle-play',
    icon: [512, 512, [61469, "play-circle"], "f144", "M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c7.6-4.2 16.8-4.1 24.3 .5l144 88c7.1 4.4 11.5 12.1 11.5 20.5s-4.4 16.1-11.5 20.5l-144 88c-7.4 4.5-16.7 4.7-24.3 .5s-12.3-12.2-12.3-20.9V168c0-8.7 4.7-16.7 12.3-20.9z"]
  };
  var faCircleCheck = {
    prefix: 'far',
    iconName: 'circle-check',
    icon: [512, 512, [61533, "check-circle"], "f058", "M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"]
  };
  var faMoneyBills = {
    prefix: 'far',
    iconName: 'money-bills',
    icon: [640, 512, [], "e1f3", "M528 80c0 35.3 28.7 64 64 64V272c-35.3 0-64 28.7-64 64H208c0-35.3-28.7-64-64-64V144c35.3 0 64-28.7 64-64H528zM160 32c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64H576c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160zM448 208a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120V360c0 66.3 53.7 120 120 120H520c13.3 0 24-10.7 24-24s-10.7-24-24-24H120c-39.8 0-72-32.2-72-72V120z"]
  };
  var faLeaf = {
    prefix: 'far',
    iconName: 'leaf',
    icon: [512, 512, [], "f06c", "M149.6 234.4c20.9-6.7 43.2-10.4 66.4-10.4h80c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-25.4 0-49.5 5.6-71 15.7C152.7 351 206.6 400 271.9 400h.1l.8 0 .1 0C370.2 399.5 464 299.7 464 156.6c0-12.8-.8-25.3-2.3-37.5C437 134.9 407.6 144 376 144l-104 0c-57.6 0-106.3 38.1-122.4 90.4zM96.4 260.1C102.5 168.5 178.8 96 272 96l104 0c28.7 0 54.8-10.8 74.6-28.5c.7-.6 1.4-1.3 2.1-1.9c6.6-6.2 12.5-13.2 17.4-20.9c1.6-2.5 3.2-5.1 4.6-7.8c3.5-6.5 13.6-6.8 16.2 .1c1.3 3.5 2.5 7 3.7 10.6c2.9 8.9 5.5 17.9 7.8 27.2c.5 1.9 .9 3.8 1.3 5.6c5.4 24.3 8.3 49.8 8.3 76.2C512 317.1 405.1 447.3 273 448l-1 0c-81.7 0-150.4-55.7-170.2-131.2C68.7 347.5 48 391.3 48 440v16c0 13.3-10.7 24-24 24s-24-10.7-24-24V440c0-75.1 38.3-141.2 96.4-179.9z"]
  };
  var faHeart = {
    prefix: 'far',
    iconName: 'heart',
    icon: [512, 512, [128153, 128154, 128155, 128156, 128420, 129293, 129294, 129505, 9829, 10084, 61578], "f004", "M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"]
  };
  var faCirclePause = {
    prefix: 'far',
    iconName: 'circle-pause',
    icon: [512, 512, [62092, "pause-circle"], "f28b", "M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm224-72V328c0 13.3-10.7 24-24 24s-24-10.7-24-24V184c0-13.3 10.7-24 24-24s24 10.7 24 24zm112 0V328c0 13.3-10.7 24-24 24s-24-10.7-24-24V184c0-13.3 10.7-24 24-24s24 10.7 24 24z"]
  };
  var faCircleQuestion = {
    prefix: 'far',
    iconName: 'circle-question',
    icon: [512, 512, [62108, "question-circle"], "f059", "M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"]
  };
  var faHexagon = {
    prefix: 'far',
    iconName: 'hexagon',
    icon: [512, 512, [11043], "f312", "M17.1 220c-12.9 22.3-12.9 49.7 0 72l88.3 152.9c12.9 22.3 36.6 36 62.4 36l176.6 0c25.7 0 49.5-13.7 62.4-36L494.9 292c12.9-22.3 12.9-49.7 0-72L406.6 67.1c-12.9-22.3-36.6-36-62.4-36l-176.6 0c-25.7 0-49.5 13.7-62.4 36L17.1 220zm41.6 48c-4.3-7.4-4.3-16.6 0-24L146.9 91.1c4.3-7.4 12.2-12 20.8-12l176.6 0c8.6 0 16.5 4.6 20.8 12L453.4 244c4.3 7.4 4.3 16.6 0 24L365.1 420.9c-4.3 7.4-12.2 12-20.8 12l-176.6 0c-8.6 0-16.5-4.6-20.8-12L58.6 268z"]
  };
  var faComment = {
    prefix: 'far',
    iconName: 'comment',
    icon: [512, 512, [128489, 61669], "f075", "M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z"]
  };
  var faCircleInfo = {
    prefix: 'far',
    iconName: 'circle-info',
    icon: [512, 512, ["info-circle"], "f05a", "M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V248c0-13.3-10.7-24-24-24H216c-13.3 0-24 10.7-24 24s10.7 24 24 24h24v64H216zm40-144a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"]
  };
  var faCommentsQuestion = {
    prefix: 'far',
    iconName: 'comments-question',
    icon: [640, 512, [], "e14e", "M80.7 253.2c14.3 15 17.3 37.6 7.5 55.8c-1.1 2-2.2 4-3.2 5.9c-2.5 4.5-5.2 9-7.9 13.6c17.1-4.5 33.9-10.7 49.9-18c4.3-1.9 8.5-3.9 12.6-6c9.5-4.8 20.3-6.2 30.7-4.2c12.1 2.4 24.8 3.6 37.8 3.6c96.2 0 160-64.5 160-128s-63.8-128-160-128S48 112.5 48 176c0 28 11.4 54.9 32.7 77.2zM416 176c0 97.2-93.1 176-208 176c-16.2 0-31.9-1.6-47.1-4.5c-4.6 2.3-9.4 4.6-14.2 6.8C110.5 370.7 67 384 24 384c-9.6 0-18.2-5.7-22-14.5c-3.8-8.8-2-19 4.6-25.9c14.2-15.6 26.2-33.7 36.6-52.1c.9-1.7 1.9-3.4 2.8-5.1C17.2 256.1 0 217.8 0 176C0 78.8 93.1 0 208 0S416 78.8 416 176zM245.2 381.5c17.2-2.4 34-6.3 50-11.8C322 405.5 370.3 432 432 432c13.1 0 25.8-1.3 37.8-3.6c10.4-2 21.2-.6 30.7 4.2c4.1 2.1 8.3 4.1 12.6 6c16 7.2 32.9 13.5 49.9 18c-2.8-4.6-5.4-9.1-7.9-13.6c-1.1-1.9-2.2-3.9-3.2-5.9c-9.8-18.3-6.8-40.8 7.5-55.8C580.6 358.9 592 332 592 304c0-59.9-56.8-120.7-144-127.4l0-.6c0-16.6-2.2-32.6-6.2-47.8C552.1 132.5 640 209.6 640 304c0 41.8-17.2 80.1-45.9 110.3c.9 1.7 1.9 3.5 2.8 5.1c10.3 18.4 22.3 36.5 36.6 52.1c6.6 7 8.3 17.2 4.6 25.9c-3.8 8.8-12.5 14.5-22 14.5c-43 0-86.5-13.3-122.7-29.7c-4.8-2.2-9.6-4.5-14.2-6.8c-15.1 3-30.9 4.5-47.1 4.5c-82 0-153-40.2-186.8-98.5zM147.9 112.1c5.5-15.4 20.1-25.7 36.4-25.7h41.3c24.2 0 43.7 19.6 43.7 43.7c0 15.7-8.4 30.1-22 37.9L224 181.4v.3c0 8.8-7.2 16-16 16s-16-7.2-16-16v-9.5c0-5.7 3.1-11 8-13.9l31.4-18c3.6-2.1 5.9-6 5.9-10.2c0-6.5-5.3-11.7-11.7-11.7H184.3c-2.8 0-5.3 1.8-6.3 4.4l-.3 .9c-3 8.3-12.1 12.7-20.4 9.7s-12.7-12.1-9.7-20.4l.3-.9zM185.3 244a22.7 22.7 0 1 1 45.3 0 22.7 22.7 0 1 1 -45.3 0z"]
  };
  var faLoader = {
    prefix: 'far',
    iconName: 'loader',
    icon: [512, 512, [], "e1d4", "M280 24c0-13.3-10.7-24-24-24s-24 10.7-24 24v80c0 13.3 10.7 24 24 24s24-10.7 24-24V24zm0 384c0-13.3-10.7-24-24-24s-24 10.7-24 24v80c0 13.3 10.7 24 24 24s24-10.7 24-24V408zM0 256c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H24c-13.3 0-24 10.7-24 24zm408-24c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H408zM437 75c-9.4-9.4-24.6-9.4-33.9 0l-56.6 56.6c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0L437 108.9c9.4-9.4 9.4-24.6 0-33.9zM165.5 380.4c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L75 403.1c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l56.6-56.6zM75 75c-9.4 9.4-9.4 24.6 0 33.9l56.6 56.6c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L108.9 75c-9.4-9.4-24.6-9.4-33.9 0zM380.5 346.5c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L403.1 437c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-56.6-56.6z"]
  };
  var faHouse = {
    prefix: 'far',
    iconName: 'house',
    icon: [576, 512, [127968, 63498, 63500, "home", "home-alt", "home-lg-alt"], "f015", "M272.5 5.7c9-7.6 22.1-7.6 31.1 0l264 224c10.1 8.6 11.4 23.7 2.8 33.8s-23.7 11.3-33.8 2.8L512 245.5V432c0 44.2-35.8 80-80 80H144c-44.2 0-80-35.8-80-80V245.5L39.5 266.3c-10.1 8.6-25.3 7.3-33.8-2.8s-7.3-25.3 2.8-33.8l264-224zM288 55.5L112 204.8V432c0 17.7 14.3 32 32 32h48V312c0-22.1 17.9-40 40-40H344c22.1 0 40 17.9 40 40V464h48c17.7 0 32-14.3 32-32V204.8L288 55.5zM240 464h96V320H240V464z"]
  };
  var faHome = faHouse;
  var faGameBoardSimple = {
    prefix: 'far',
    iconName: 'game-board-simple',
    icon: [448, 512, ["game-board-alt"], "f868", "M384 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H384zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm16 80V256H224V112H80zM224 256V400H368V256H224z"]
  };
  var faBell = {
    prefix: 'far',
    iconName: 'bell',
    icon: [448, 512, [128276, 61602], "f0f3", "M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v25.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416H424c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm0 96c61.9 0 112 50.1 112 112v25.4c0 47.9 13.9 94.6 39.7 134.6H72.3C98.1 328 112 281.3 112 233.4V208c0-61.9 50.1-112 112-112zm64 352H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z"]
  };
  var faTimer = {
    prefix: 'far',
    iconName: 'timer',
    icon: [512, 512, [], "e29e", "M232 24c0-13.3 10.7-24 24-24C397.4 0 512 114.6 512 256s-114.6 256-256 256S0 397.4 0 256c0-37.9 8.2-73.8 23-106.1c6-13.2 13.1-25.8 21.2-37.6c0-.1 .1-.1 .1-.2C53.4 98.7 63.6 86.3 75 75c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9c-9.2 9.2-17.6 19.3-25 30.1c0 .1-.1 .1-.1 .2c-21.2 31.2-34.2 68.5-35.7 108.7c-.1 2.7-.2 5.4-.2 8.1c0 114.9 93.1 208 208 208s208-93.1 208-208c0-106.8-80.4-194.7-184-206.6V104c0 13.3-10.7 24-24 24s-24-10.7-24-24V24zM159 159c9.4-9.4 24.6-9.4 33.9 0l80 80c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-80-80c-9.4-9.4-9.4-24.6 0-33.9z"]
  };
  var faPlus = {
    prefix: 'far',
    iconName: 'plus',
    icon: [448, 512, [10133, 61543, "add"], "2b", "M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z"]
  };
  var faCirclePlus = {
    prefix: 'far',
    iconName: 'circle-plus',
    icon: [512, 512, ["plus-circle"], "f055", "M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344c0 13.3 10.7 24 24 24s24-10.7 24-24V280h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H280V168c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H168c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z"]
  };

  exports.faBarcodeRead = faBarcodeRead;
  exports.faBell = faBell;
  exports.faCircleCheck = faCircleCheck;
  exports.faCircleInfo = faCircleInfo;
  exports.faCirclePause = faCirclePause;
  exports.faCirclePlay = faCirclePlay;
  exports.faCirclePlus = faCirclePlus;
  exports.faCircleQuestion = faCircleQuestion;
  exports.faComment = faComment;
  exports.faCommentsQuestion = faCommentsQuestion;
  exports.faGameBoardSimple = faGameBoardSimple;
  exports.faHeart = faHeart;
  exports.faHexagon = faHexagon;
  exports.faHome = faHome;
  exports.faLeaf = faLeaf;
  exports.faLoader = faLoader;
  exports.faMoneyBills = faMoneyBills;
  exports.faPlus = faPlus;
  exports.faStar = faStar;
  exports.faTimer = faTimer;
  exports.faTrashAlt = faTrashAlt;
  exports.faUser = faUser;
  exports.faUsers = faUsers;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
